import { Box, Heading, Image, Link, Stack } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';

const Contact = ({
  bgColor,
  title,
  imgPosition,
  subtitlu,
  dataAosImg,
  dataAosTxt,
}) => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <Stack
      w={'full'}
      alignItems={'center'}
      justifyContent={'center'}
      bg={bgColor}
    >
      <Box
        w={'full'}
        maxW={'90rem'}
        display={'grid'}
        zIndex={1}
        minH={'100vh'}
        placeItems={'center'}
      >
        <Box
          display={'grid'}
          gridAutoColumns={'minmax(auto, 1fr)'}
          alignItems={'center'}
          gridTemplateAreas={{
            base: imgPosition ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`,
            lg: imgPosition ? `'col2 col1'` : `'col1 col2'`,
          }}
        >
          <Box
            gridArea={'col1'}
            p={{ base: '8', md: 0 }}
            data-aos={dataAosImg}
            data-aos-duration={1500}
            data-aos-easing={'ease-in-out'}
            data-aos-delay={0}
          >
            <Image
              src={'/images/Emails.gif'}
              alt={
                'Tineret Forta Dreptei - Stop Reclamelor La Jocurile de Noroc'
              }
            />
          </Box>
          <Stack
            gridArea={'col2'}
            w={{ base: '350px', lg: '700px' }}
            p={{ base: '8', md: '8' }}
            data-aos={dataAosTxt}
            data-aos-duration={1500}
            data-aos-easing={'ease-in-out'}
            data-aos-delay={0}
            gap={'2rem'}
          >
            <Heading as={'h2'} fontSize={'1.6rem'} w={'fit-content'}>
              {title}
            </Heading>
            <Heading as={'h3'} fontSize={'1.3rem'} w={'fit-content'}>
              {subtitlu}
            </Heading>

            <Link
              href="mailto:contact@tineretfortadreptei.ro"
              bg={'brand'}
              borderRadius={'1.25rem'}
              w={'fit-content'}
              color={'textLight'}
              px={'1.5rem'}
              py={'.5rem'}
              fontWeight={'bold'}
            >
              contact@tineretfortadreptei.ro
            </Link>
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
};

export default Contact;

import { Stack } from '@chakra-ui/react';
import React from 'react';
import { Cta, Cta2, Hero, InfoSection } from '../components';
import Contact from '../components/home/Contact';
import {
  infoContact,
  infoFour,
  infoOne,
  infoThree,
  infoTwo,
} from '../components/home/infoData';
import { Helmet } from 'react-helmet-async';
import GradientBg from '../components/home/gradientBg';

const Home = () => {
  return (
    <Stack
      alignItems={'center'}
      justifyContent={'center'}
      overflow={'hidden'}
      w={'full'}
      m={0}
      mt={{ base: 0, lg: '-150px' }}
    >
      <Helmet>
        <title>Stop Reclamelor la Jocurile de Noroc</title>
      </Helmet>
      <Hero />
      <Cta2 />
      <GradientBg reverse={true} />
      <InfoSection {...infoOne} /> {/*info 1 */}
      <GradientBg />
      <InfoSection {...infoTwo} /> {/*info 2 */}
      <Cta />
      <GradientBg reverse={true} />
      <InfoSection {...infoThree} /> {/*info 3 */}
      <GradientBg />
      <InfoSection {...infoFour} /> {/*info 4 */}
      <GradientBg reverse={true} />
      <Contact {...infoContact} /> {/* contact */}
    </Stack>
  );
};

export default Home;

export const infoOne = {
  bgColor: '#fff',
  image: '/images/info1.png',
  title: 'Un studiu belgian arată că:',
  text: [
    '74% dintre cetățeni cred că publicitatea încurajează oamenii să parieze.',
    '54% dintre cetățeni cred că publicitatea îi face pe oameni să gândească mai pozitiv despre jocurile de noroc (acceptare).',
    'În plus, 11% dintre cetățenii care nu au participat în niciun fel la jocuri de noroc spun că, datorită influenței publicității, ar dori să joace în viitor.',
    'În rândul studenților, această cifră crește la 14,8%, în rândul celor de 25-34 de ani la 17,3% și în rândul persoanelor aflate în căutarea unui loc de muncă la 28,9%.',
  ],
  imgPosition: false,
  dataAosTxt: 'fade-left',
  dataAosImg: 'fade-right',
};

export const infoTwo = {
  bgColor: 'brand',
  image: '/images/info2.jpg',
  title: 'Publicitatea pentru jocurile de noroc este omniprezentă!',
  text: [
    ' La televizor',
    'La radio, pe rețelele de socializare',
    'În timpul competițiilor sportive',
    'În stradă',
    'O astfel de publicitate este un pericol pentru sănătatea publică și pentru societate.',
  ],
  imgPosition: true,
  dataAosTxt: 'fade-right',
  dataAosImg: 'fade-left',
};
export const infoThree = {
  bgColor: '#fff',
  image: '/images/info3.jpg',
  title:
    'Publicitatea pentru jocurile de noroc are cel puțin trei efecte majore: ',
  text: [
    'Joacă un rol important în recrutarea de noi jucători',
    'Influențează comportamentul de joc și încurajează jucătorii să joace mai intens',
    'Crește probabilitatea de recidivă în rândul jucătorilor cu dependență de jocuri de noroc',
  ],
  imgPosition: false,
  dataAosTxt: 'fade-left',
  dataAosImg: 'fade-right',
};
export const infoFour = {
  bgColor: 'brand',
  image: '/images/info4.jpg',
  title: 'Jucătorii sunt mai influențați de mesajele publicitare!',
  title2:
    'Aproximativ unul din trei jucători lasă comportamentul lor de joc să fie influențat de publicitate, atât stradală, cât și în audiovizual.',
  text: [
    '56% dintre jucătorii intensivi au pariat mai mult după ce li s-a oferit un bonus',
    '46% după ce au primit un e-mail de la o companie de jocuri de noroc',
    '39% după ce au vizionat reclame pentru jocuri de noroc',
  ],
  imgPosition: true,
  dataAosTxt: 'fade-right',
  dataAosImg: 'fade-left',
};

export const infoContact = {
  bgColor: '#fff',
  title: 'Vrei mai multe informații?',
  subtitlu: 'Nu ezita să ne contactezi!',
  imgPosition: false,
  dataAosTxt: 'fade-left',
  dataAosImg: 'fade-right',
};

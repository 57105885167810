export const gdprText = [
  {
    title:
      'ACCORD ȘI RĂSPUNDERI PIVIND PRELUCRAREA DATELOR CU CARACTER PERSONAL',
    text: [
      <strong>
        Îmi exprim consimțământul cu privire la prelucrarea datelor mele pentru
        a primi informări cu privire la campanii, evenimente, acțiuni organizate
        sau susținute de către Forța Dreptei, precum și alte înștiințări sau
        materiale informative ale Forța Dreptei.
      </strong>,
      <p>
        <strong>
          În cazul în care voi dori să îmi retrag consimțământul cu privire la
          prelucrarea datelor mele cu caracter personal, voi transmite o
          informare scrisă în acest sens, specificând și scopul/scopurile
          prelucrărilor pentru care doresc să opereze retragerea
          consimțământului.
        </strong>
      </p>,
    ],
  },
];

import {
  Box,
  Heading,
  Image,
  ListItem,
  Stack,
  UnorderedList,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';

const InfoSection = ({
  bgColor,
  image,
  title,
  title2,
  text,
  imgPosition,
  dataAosTxt,
  dataAosImg,
}) => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <Stack
      overflow={'hidden'}
      w={'full'}
      alignItems={'center'}
      justifyContent={'center'}
      bg={bgColor}
      color={imgPosition ? 'textLight' : 'textDark'}
    >
      <Box
        w={'full'}
        maxW={'90rem'}
        display={'grid'}
        zIndex={1}
        minH={'100vh'}
        placeItems={'center'}
      >
        <Box
          display={'grid'}
          gridAutoColumns={'minmax(auto, 1fr)'}
          alignItems={'center'}
          gridTemplateAreas={{
            base: imgPosition ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`,
            lg: imgPosition ? `'col2 col1'` : `'col1 col2'`,
          }}
          gap={'1rem'}
        >
          <Box
            gridArea={'col1'}
            p={{ base: '8', md: 0 }}
            data-aos={dataAosImg}
            data-aos-duration={1500}
            data-aos-easing={'ease-in-out'}
            data-aos-delay={0}
          >
            <Image
              borderRadius={'1.5rem'}
              w={{ base: '350px', lg: '700px' }}
              h={{ base: '200px', lg: '450px' }}
              objectFit={'cover'}
              src={image}
              alt={
                'Tineret Forta Dreptei - Stop Reclamelor La Jocurile de Noroc'
              }
            />
          </Box>
          <Stack
            gridArea={'col2'}
            w={{ base: '350px', lg: '600px' }}
            p={{ base: '8', md: '8' }}
            data-aos={dataAosTxt}
            data-aos-duration={1500}
            data-aos-easing={'ease-in-out'}
            data-aos-delay={0}
          >
            <Heading
              as={'h2'}
              w={'fit-content'}
              color={imgPosition ? 'titleLight' : 'titleDark'}
              fontSize={'1.6rem'}
            >
              {title}
            </Heading>
            <UnorderedList fontSize={'1.3rem'}>
              {text.map((item, index) => (
                <ListItem key={index} mb={'.8rem'}>
                  {item}
                </ListItem>
              ))}
            </UnorderedList>
            <Heading
              w={'fit-content'}
              color={imgPosition ? 'titleLight' : 'titleDark'}
              fontSize={'1.6rem'}
            >
              {title2}
            </Heading>
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
};

export default InfoSection;

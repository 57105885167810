import { chakra, Box, Stack } from '@chakra-ui/react';
import React from 'react';

const Hero = () => {
  return (
    <Stack
      w={'full'}
      h={{ base: '26vh', md: '40vh', lg: '100vh' }}
      alignItems={'center'}
      justifyContent={'center'}
      className="heroStack"
      position={'relative'}
    >
      <Box
        inset={'0 0 0 0'}
        w={'full'}
        h={{ base: '26vh', md: '40vh', lg: '100vh' }}
        zIndex={1}
        position={'absolute'}
      >
        <Box
          w={'full'}
          h={{ base: '26vh', md: '40vh', lg: '100vh' }}
          bg={'rgba(0,0,0,0.35)'}
          inset={'0 0 0 0'}
          zIndex={2}
        >
          <chakra.video
            w={'full'}
            h={{ base: '26vh', md: '40vh', lg: '100vh' }}
            objectFit={'cover'}
            objectPosition={'center'}
            src="/images/hero.mp4"
            autoPlay={true}
            loop={true}
            controls={false}
            muted
            playsInline
            type="video/mp4"
          />
          <Box
            w={'full'}
            h={{ base: 'auto', md: '40vh', lg: '100vh' }}
            bg={{ base: 'transparent', lg: 'rgba(0,0,0,0.6)' }}
            position={'absolute'}
            top={0}
            zIndex={3}
          ></Box>
        </Box>
      </Box>
    </Stack>
  );
};

export default Hero;

import { Box } from '@chakra-ui/react';
import React from 'react';

const GradientBg = ({ reverse }) => {
  return (
    <Box
      w={'full'}
      h={{ base: '50px', lg: '400px' }}
      bg={
        reverse
          ? 'linear-gradient(#08510A 5%, #ffffff )'
          : 'linear-gradient(#ffffff 5%, #08510A )'
      }
    ></Box>
  );
};

export default GradientBg;

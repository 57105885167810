import { HStack, Heading, Stack, Text, Link as LinkC } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const Cta2 = () => (
  <Stack
    className="ctaStack"
    w={'full'}
    minH={'50vh'}
    alignItems={'center'}
    justifyContent={'center'}
    overflow={'hidden'}
    bg={'brand'}
    margin={0}
    position={'relative'}
    py={'2rem'}
    px={{ base: '1rem', md: 0 }}
  >
    <Stack
      w={'full'}
      maxW={'900px'}
      border={'1px solid #fff'}
      borderRadius={'1rem'}
      backdropFilter={'blur(8px)'}
      bg={'rgba(255,255,255,0.08)'}
      data-aos={'zoom-in'}
      data-aos-duration={1500}
      data-aos-easing={'ease-in-out'}
      data-aos-delay={300}
      color={'textLight'}
      p={'1rem'}
    >
      <Stack gap={'2rem'} align="center" p={'1rem'}>
        <Heading
          as={'h1'}
          fontSize={{ base: '1.4rem', lg: '1.9rem' }}
          fontWeight={'bold'}
          w={'fit-content'}
          textAlign={'center'}
        >
          În acest moment în Parlamentul României, în circuit parlamentar, se
          află un proiect de lege care prevede <br />
          INTERZICEREA TOTALĂ A RECLAMELOR LA JOCURILE DE NORORC.
        </Heading>

        <Text
          color="muted"
          maxW={{ base: '300px', md: '500px' }}
          textAlign="center"
          fontSize={{ base: '1rem', lg: '1.5rem' }}
        >
          A fost depus la{' '}
          <LinkC
            textDecoration={'underline'}
            color={'#fff'}
            target="_blank"
            aria-label="Camera Deputaților"
            rel="noreferrer"
            href="https://www.cdep.ro/pls/proiecte/upl_pck2015.proiect?idp=20782"
          >
            Camera Deputaților
          </LinkC>
        </Text>

        <Text
          color="muted"
          maxW={{ base: '300px', md: '500px' }}
          textAlign="center"
          fontSize={{ base: '1rem', lg: '1.5rem' }}
        >
          În acest moment se află la{' '}
          <LinkC
            textDecoration={'underline'}
            color={'#fff'}
            target="_blank"
            aria-label="Senat"
            rel="noreferrer"
            href="https://senat.ro/legis/lista.aspx?nr_cls=L267&an_cls=2023"
          >
            Senat
          </LinkC>
        </Text>

        <Text
          color="muted"
          maxW={{ base: '300px', md: '500px' }}
          textAlign="center"
          fontSize={{ base: '1rem', lg: '1.5rem' }}
        >
          Haideți să punem presiune pe parlamentari să voteze acest proiect!
          Doar uniți îi putem convinge!
        </Text>
      </Stack>
      <HStack justify="center" alignItems={'center'}>
        <Link to={'/sustine-initiativa'}>
          {' '}
          <HStack
            w={'150px'}
            h={'50px'}
            borderRadius={'1rem'}
            bg={'#e8e8e8'}
            justify="center"
            alignItems={'center'}
            fontWeight={'bold'}
            color={'titleDark'}
          >
            <Text>Susține-ne!</Text>
          </HStack>
        </Link>
      </HStack>
    </Stack>
  </Stack>
);

export default Cta2;

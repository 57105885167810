import {
  Box,
  HStack,
  Heading,
  Stack,
  Stat,
  StatArrow,
  StatHelpText,
  StatLabel,
  StatNumber,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useContext, useEffect, useReducer } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Store } from '../contexts/Store';
import { getError } from '../Utils';
import { LoadingBox, MessageBox } from '../components';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        loading: false,
        forms: action.payload.forms,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
function Dashboard() {
  const [{ loading, error, forms, pages }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
    forms: [],
  });

  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const page = sp.get('page') || 1;

  const { state } = useContext(Store);
  const { userInfo } = state;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `https://testapi.autopost.ro/api/form/admin?page=${page}`,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
      }
    };

    fetchData();
  }, [page, userInfo]);

  return (
    <Stack
      w={'full'}
      minH={'100vh'}
      mt={{ base: '-100px', lg: '-150px' }}
      mx="3rem"
      py="4rem"
      justify={'flex-start'}
      alignItems={'center'}
      bg={'brand'}
    >
      <HStack
        mt={{ base: '100px', lg: '150px' }}
        maxW={'1100px'}
        w={'full'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Heading as="h1" color={'titleLight'}>
          Numărul total de semnături
        </Heading>
      </HStack>
      <HStack>
        <Stat>
          <StatLabel>Total Semnături:</StatLabel>
          <StatNumber>{1}</StatNumber>
          <StatHelpText>
            <StatArrow type="increase" />
            23.36%
          </StatHelpText>
        </Stat>
      </HStack>
      {loading ? (
        <LoadingBox />
      ) : error ? (
        <MessageBox status="error">{error}</MessageBox>
      ) : (
        <Stack
          w={'full'}
          h={'580px'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <TableContainer maxW={'100rem'} w="full">
            <Table variant="simple">
              <Thead bg={'secondary'}>
                <Tr>
                  <Th border={'1px solid #fff'} color={'textLight'}>
                    NUME
                  </Th>
                  <Th border={'1px solid #fff'} bg={'#fff'} color={'textDark'}>
                    PRENUME
                  </Th>
                  <Th border={'1px solid #fff'} color={'textLight'}>
                    ADRESĂ
                  </Th>
                  <Th
                    w={'13ch'}
                    border={'1px solid #fff'}
                    bg={'#fff'}
                    color={'textDark'}
                  >
                    SERIE ȘI NUMĂR C.I SAU CNP
                  </Th>
                  <Th border={'1px solid #fff'} color={'textLight'}>
                    TELEFON
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {forms.map((form) => (
                  <Tr key={form._id}>
                    <Td
                      border={'1px solid #fff'}
                      bg={'#fff'}
                      color={'textDark'}
                    >
                      {form.lastName}
                    </Td>
                    <Td
                      border={'1px solid #fff'}
                      bg={'secondary'}
                      color={'textLight'}
                    >
                      {form.firstName}
                    </Td>
                    <Td
                      border={'1px solid #fff'}
                      bg={'#fff'}
                      color={'textDark'}
                    >
                      {form.address}
                    </Td>
                    <Td
                      w={'13ch'}
                      border={'1px solid #fff'}
                      bg={'secondary'}
                      color={'textLight'}
                    >
                      {form.serie}
                    </Td>
                    <Td
                      border={'1px solid #fff'}
                      bg={'#fff'}
                      color={'textDark'}
                    >
                      {form.phone}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Box w="full" maxW={'100rem'} display={'flex'}>
            {[...Array(pages).keys()].map((x) => (
              <Link
                bg={x + 1 === Number(page) ? 'secondary' : '#fff'}
                key={x + 1}
                to={`/admin?page=${x + 1}`}
              >
                <Text
                  border={'1px solid #fff'}
                  boxSize={'35px'}
                  mr={3}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  borderRadius={'0.3rem'}
                  color={'textLight'}
                  fontWeight={'bold'}
                >
                  {x + 1}
                </Text>
              </Link>
            ))}
          </Box>
        </Stack>
      )}
    </Stack>
  );
}

export default Dashboard;

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Link,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { gdprText } from '../constants/policies';

const Gdpr = () => {
  return (
    <Flex
      w={'full'}
      justifyContent={'center'}
      alignItems={'center'}
      bg={'brand'}
      color={'textLight'}
      mt={'-150px'}
      h={'100vh'}
    >
      <Helmet>
        <title>Politica GDPR - Stop Reclame Jocuri Noroc</title>
      </Helmet>
      <Flex
        flexDir={'column'}
        w={'full'}
        maxW={'100rem'}
        flexDirection={'column'}
      >
        <Heading as={'h1'}>Politica GDPR</Heading>
        <Text>Ultima updatare February 08, 2023</Text>
        <Text>
          Această politica GDPR aparține Forța Dreptei (
          <strong>'Partidul', 'noi', 'ale noastre', sau 'al nostru'</strong>
          ), descrie de ce am putea colecta, păstra și folosi date cu caracter
          personal, (<strong>'procesăm'</strong>) informațiile dumneavoastră,
          când ne utilizați serviciile/platformele (
          <strong>'Serviciile'</strong>), atunci când:
        </Text>
        <UnorderedList>
          <ListItem>
            Ne vizitați pe website-ul https://fortadreptei.ro sau orice alt
            website deținut de noi ce utilizeaza politica noastră de GDPR
          </ListItem>
          <ListItem>
            Interacționați cu noi in orice mod relevant, incluzand campanii,
            evenimente, acțiuni organizate sau susținute de către noi
          </ListItem>
        </UnorderedList>

        <Text>
          Aveți nelămuriri sau neîntelegeri? Citiși aceasta politica de
          confidențialitate pentru a va ajuta să întelegeți drepturile la
          confidențialitate. Dacă nu sunteți de accord cu politicile și
          practicile noastre, vă rugăm să nu ne folosiți Serviciile. Dacă aveți
          întrebări, vă rugăm să ne contactați pe mail:{' '}
          <Link
            textDecoration={'underline'}
            href="mailto:contact@fortadreptei.ro"
          >
            contact@fortadreptei.ro
          </Link>
        </Text>
        <Accordion my="2rem" allowToggle allowMultiple>
          {gdprText.map((item) => (
            <AccordionItem key={item.title}>
              <h2>
                <AccordionButton>
                  <Box flex={1} textAlign="left">
                    <Heading as="h3" fontSize="2xl" textTransform="uppercase">
                      {item.title}
                    </Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                {item.text.map((item, index) => (
                  <Box key={index} mb="1rem" textAlign="justify">
                    {item}
                  </Box>
                ))}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Flex>
    </Flex>
  );
};

export default Gdpr;

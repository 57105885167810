import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Image,
  Stack,
  Link as LinkH,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <Drawer w={'70%'} isOpen={isOpen} placement="right" onClose={toggle}>
      <DrawerOverlay />
      <DrawerContent bg={'secondary'} px={'2rem'} py={'2rem'} color={'#fff'}>
        <Flex
          w={'full'}
          flexDir={'column'}
          fontWeight={'bold'}
          letterSpacing={'2px'}
          fontSize={'1.2rem'}
          flex={1}
          gap={'3rem'}
        >
          <HStack w={'full'} justifyContent={'space-between'}>
            <Link to={'/'}>
              <Image
                w={'100px'}
                objectFit={'contain'}
                src={'/images/LogoTFD-alb.png'}
                alt={
                  'Tineret Forta Dreptei - Stop Reclamelor La Jocurile de Noroc'
                }
              />
            </Link>
            <CloseIcon onClick={toggle} />
          </HStack>
          <Stack alignItems={'flex-start'} spacing={'2rem'}>
            <Box onClick={toggle}>
              <Link to={'/'}>Acasa</Link>
            </Box>
            <Box onClick={toggle}>
              <Link to={'/sustine-initiativa'}>Sustine Initiativa</Link>
            </Box>
            <Box onClick={toggle}>
              <LinkH
                href={'https://forta-dreptei.ro/'}
                target="_blank"
                aria-label="Forta Dreptei"
                rel="noreferrer"
              >
                Despre Noi
              </LinkH>
            </Box>
          </Stack>
        </Flex>
      </DrawerContent>
    </Drawer>
  );
};

export default Sidebar;

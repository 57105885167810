import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Store } from '../contexts/Store';
import { toast } from 'react-toastify';
import { getError } from '../Utils';
import { HiEye, HiEyeOff } from 'react-icons/hi';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { dispatch: ctxDispatch } = useContext(Store);
  const { isOpen, onToggle } = useDisclosure();
  const onClickReveal = () => {
    onToggle();
  };
  const logInHandler = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        'https://testapi.autopost.ro/api/users/signin',
        {
          email,
          password,
        }
      );
      ctxDispatch({ type: 'USER_SIGNIN', payload: data });
      localStorage.setItem('userInfo', JSON.stringify(data));
      toast.success('Te-ai logat cu succes!');
      navigate('/admin');
    } catch (error) {
      toast.error(getError(error));
    }
  };
  return (
    <Container
      maxW="lg"
      py={{
        base: '12',
        md: '24',
      }}
      px={{
        base: '0',
        sm: '8',
      }}
    >
      <Stack spacing="8">
        <Stack spacing="6" alignItems={'center'} textAlign={'center'}>
          <Image
            w={'200px'}
            src={'/images/LogoTFD.png'}
            alt={'Tineret Forta Dreptei - Stop Reclamelor La Jocurile de Noroc'}
          />

          <Heading
            size={{
              base: 'xs',
              md: 'sm',
            }}
          >
            Conecteaza contul de administrator
          </Heading>
        </Stack>
        <Box
          py={{
            base: '0',
            sm: '8',
          }}
          px={{
            base: '4',
            sm: '10',
          }}
          bg={{
            base: 'transparent',
            sm: 'bg-surface',
          }}
          boxShadow={{
            base: 'none',
            sm: 'md',
          }}
          borderRadius={{
            base: 'none',
            sm: 'xl',
          }}
        >
          <form onSubmit={logInHandler}>
            <Stack spacing="6">
              <Stack spacing="5">
                <FormControl isRequired>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                    borderColor={'#000'}
                    w="300px"
                    id="email"
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel htmlFor="password">Parola:</FormLabel>
                  <InputGroup>
                    <InputRightElement>
                      <IconButton
                        variant="link"
                        aria-label={isOpen ? 'Ascunde parola' : 'Arata parola'}
                        icon={isOpen ? <HiEyeOff /> : <HiEye />}
                        onClick={onClickReveal}
                      />
                    </InputRightElement>
                    <Input
                      borderColor={'#000'}
                      id="password"
                      name="password"
                      type={isOpen ? 'text' : 'password'}
                      autoComplete="current-password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </InputGroup>
                </FormControl>
              </Stack>

              <Stack spacing="6">
                <Button type={'submit'} variant="primary">
                  Logheaza-te
                </Button>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Container>
  );
};

export default Login;

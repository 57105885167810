import {
  Box,
  HStack,
  Icon,
  Image,
  Link,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import React from 'react';
import { FaFacebook, FaInstagram, FaCopyright } from 'react-icons/fa';

const Footer = () => {
  return (
    <HStack
      w={'full'}
      minH={'100px'}
      bg={'brand'}
      alignItems={'center'}
      justifyContent={'center'}
      color={'#e8e8e8'}
      gap={['2rem', 0]}
      boxShadow={'0 -1rem 1.5rem rgba(0,0,0,0.2)'}
      py={{ base: '2rem', lg: 0 }}
    >
      <HStack
        w={'full'}
        maxW={'90rem'}
        justifyContent={{ base: 'center', lg: 'space-between' }}
        flexWrap={'wrap'}
        gap={{ base: '1rem', lg: 0 }}
      >
        <Box>
          <Image w={'125px'} src={'/images/LogoTFD-alb.png'} />
        </Box>
        <HStack
          alignItems={'center'}
          justifyContent={'center'}
          flexWrap={'wrap'}
          gap={'.5rem'}
        >
          <Text
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            flexWrap={'wrap'}
            gap={'.5rem'}
          >
            Created by{' '}
            <Link
              color={'#ccc6f5'}
              target="_blank"
              aria-label="Peak & Go"
              rel="noreferrer"
              href={'https://peakngo.com'}
            >
              Peak & Go - Web.
            </Link>{' '}
          </Text>
          <Text
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            flexWrap={'wrap'}
            gap={'.5rem'}
          >
            All rights reserved <Icon fontSize={'1.25rem'} as={FaCopyright} />{' '}
            Tineret Forța Dreptei
          </Text>
        </HStack>
        <Box>
          <UnorderedList
            m={0}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            gap={'2rem'}
            listStyleType={'none'}
            fontSize={'1.5rem'}
          >
            <ListItem>
              <Link
                _hover={{ color: '#4267B2' }}
                href="https://www.facebook.com/TineretFortaDreptei"
                target="_blank"
                aria-label="facebook"
                rel="noreferrer"
              >
                <Icon as={FaFacebook} />
              </Link>
            </ListItem>
            <ListItem>
              <Link
                _hover={{ color: '#C13584' }}
                target="_blank"
                aria-label="instagram"
                rel="noreferrer"
                href="https://www.instagram.com/tineret.fortadreptei/"
              >
                <Icon as={FaInstagram} />
              </Link>
            </ListItem>
          </UnorderedList>
        </Box>
      </HStack>
    </HStack>
  );
};

export default Footer;

import { HStack, Heading, Stack, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const Cta = () => (
  <Stack
    className="ctaStack"
    w={'full'}
    minH={'50vh'}
    alignItems={'center'}
    justifyContent={'center'}
    overflow={'hidden'}
    bg={'brand'}
    margin={0}
    position={'relative'}
  >
    <Stack
      border={'1px solid #fff'}
      borderRadius={'1rem'}
      backdropFilter={'blur(8px)'}
      bg={'rgba(255,255,255,0.08)'}
      data-aos={'zoom-in'}
      data-aos-duration={1500}
      data-aos-easing={'ease-in-out'}
      data-aos-delay={300}
      color={'textLight'}
      p={'1rem'}
    >
      <Stack gap={'2rem'} align="center" p={'1rem'}>
        <Heading
          as={'h1'}
          fontSize={{ base: '1.4rem', lg: '1.9rem' }}
          fontWeight={'bold'}
          w={'fit-content'}
          textAlign={'center'}
        >
          Interzicem reclamele <br />
          la jocurile de noroc!
        </Heading>
        <Text
          color="muted"
          maxW={{ base: '300px', md: '500px' }}
          textAlign="center"
          fontSize={{ base: '1rem', lg: '1.5rem' }}
        >
          Inițiativa a fost avizată favorabil de către Consiliul Legislativ și
          Consiliul Economic și Social!
        </Text>
        <Text
          color="muted"
          maxW={{ base: '300px', md: '500px' }}
          textAlign="center"
          fontSize={{ base: '1rem', lg: '1.5rem' }}
        >
          Sprijină această inițiativă, să prevenim dependența de jocurile de
          noroc!
        </Text>
      </Stack>
      <HStack justify="center" alignItems={'center'}>
        <Link to={'/sustine-initiativa'}>
          {' '}
          <HStack
            w={'150px'}
            h={'50px'}
            borderRadius={'1rem'}
            bg={'#e8e8e8'}
            justify="center"
            alignItems={'center'}
            fontWeight={'bold'}
            color={'titleDark'}
          >
            <Text>Susține-ne!</Text>
          </HStack>
        </Link>
      </HStack>
    </Stack>
  </Stack>
);

export default Cta;
